<template>
    <section class="fade poppins">
       
        <section  class=" tw-sticky tw-top-0 tw-z-50 tw-bg-white">
            <img src="/bar-line.png" class="tw-w-full">
            <div class="tw-max-w-6xl tw-mx-auto ">
                <div class="tw-h-20 tw-w-full tw-flex tw-justify-between tw-items-center">
                    <div class="">
                        <v-img src="../../assets/images/logo.png" alt="Logo"
                            contain
                            height="70px"
                            width="70px" 
                        />
                    </div>
                    <div class="tw-flex tw-gap-6 tw-items-center">
                        <div @click="scrollToSection(i)" :class="` tw-font-semibold ${tab == i ? ' tw-border-b-2 tw-border-primary' : ''}`" v-for="item, i in tabs" :key=i>
                            {{item}}
                        </div>
                    </div>
                    <div class=""></div>
                </div>
            </div>
        </section>
        <section ref="homeSection" class=" tw-flex tw-flex-col tw-justify-center tw-items-center" style="min-height: calc(100vh - 100px)">
            <div class=" tw-text-primary tw-font-extrabold tw-max-w-6xl tw-text-center" style="font-size: 55px">
                Philippine Society of General Surgeons Minimally Invasive Surgery
            </div>
            <div class="tw-text-center tw-max-w-xl tw-my-7">Learn your way, anytime, anywhere - with the perfect blend of online and in-person learning!</div>
            <div class="tw-my-10">
                <button @click="$router.push('/login')" class="tw-text-[15px] tw-py-2 tw-px-12 tw-rounded tw-bg-primary tw-text-white tw-font-semibold">
                    Sign in
                </button>
            </div>
        </section>
        <div class="tw-container tw-mx-auto tw-pb-10" >
            <img src="doctor.png" alt="" class="tw-object-contain">
        </div>

        <section ref="aboutSection" class="tw-flex tw-flex-col tw-justify-center tw-items-center" style="min-height: 100vh">
            <div class="  tw-font-bold tw-max-w-6xl tw-text-center" style="font-size: 55px">
                What is <span class="tw-text-primary">Minimally Invasive Surgery</span> Hybrid Online Learning?
            </div>
            <div class="tw-text-center tw-max-w-5xl tw-my-7">
                Since the introduction of minimally invasive surgery (MIS) in the early 1990s, it has gained wide spread acceptance because of the improved outcomes, such as faster recovery, shorter hospitalization, and better quality of life, that resulted from the procedure.  
                <br>
                <br>
                The Philippine Society of General Surgeons (PSGS) has incorporated MIS into its Surgical Residency curriculum to improve knowledge in and facilitate learning of MIS. The PSGS Subcommittee on MIS has developed a standardized curriculum that will be delivered through an innovative approach with the goal of enhancing and improving the training of basic laparoscopy. This novel program is called Hybrid Online Learning (HOL), a blended-learning solution that combines the online activities plus a live, virtual face-to-face (FTF) workshop. This approach results in in a more streamlined and effective learning process
            </div>
        </section>

        <section ref="announcementSection" class="announcement tw-bg-light tw-mt-10 tw-p-10">
            <div class="">
                <div class="tw-text-center tw-font-bold tw-my-7 tw-text-xl">Announcements</div>
                <div class="tw-max-w-xl tw-mx-auto tw-grid tw-grid-cols-4 tw-gap-4 tw-my-5">
                    <div v-for="item,i in types" :key="i" 
                        :class="` tw-text-center tw-font-semibold tw-text-gray-600 tw-cursor-pointer ${type == item ? ' tw-border-b-2 tw-border-primary' : ''}`"
                        @click="fetchAnnouncements(item)"
                    >
                        {{item}}
                    </div>
                </div>
                <div class=" tw-my-20 tw-max-w-6xl tw-mx-auto tw-grid tw-grid-cols-4 tw-gap-4">
                    <div class="tw-bg-white tw-flex tw-flex-col tw-rounded tw-border" v-for="item, i in announcements" :key=i>
                        <div class="">
                            <img :src="item.banner" alt="" class=" tw-h-40 tw-w-full tw-object-cover tw-rounded-t">
                        </div>
                        <div class="tw-p-4">
                            <div class="">
                                <div class="tw-py-1 tw-w-28 tw-text-center tw-rounded tw-px-2 tw-bg-light tw-text-primary tw-text-sm tw-font-semibold">
                                    {{ item.type }}
                                </div>
                            </div>
                            <div class=" tw-font-semibold">{{ item.title }}</div>
                            <div class=" tw-text-sm">{{ $dateFormat.mmDDyy(item.created_at)  }}</div>
                        </div>
                        <div class=" tw-p-4 tw-flex-1 tw-flex tw-items-end">
                            <button @click="$router.push(`/announcement/${item.id}`)" style="font-size: 14px" class=" tw-rounded tw-py-2 tw-font-medium tw-w-full tw-bg-gray-200 tw-text-gray-700 hover:tw-bg-gray-300">
                                Read more
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section ref="courseSection">
            <div v-for="item, i in items" :key=i class="" :style="`min-height: 500px; background-image: url(${i == 0 ? '/bg-junior.png' : i == 1 ? '/bg-intermediate.png' : '/bg-senior.png'}); background-position: center; background-repeat: no-repeat; background-size: cover`">
                <div class=" tw-text-center tw-text-2xl tw-text-white tw-font-bold tw-pt-44">{{ item.title }}</div>
                <div class=" tw-text-center tw-text-white tw-font-medium tw-my-7">{{ item.description }}</div>
                <div class=" tw-pt-20 tw-pb-44 tw-max-w-6xl tw-mx-auto tw-grid tw-grid-cols-4 tw-gap-4">
                    <div class="tw-bg-white tw-p-4 tw-flex tw-flex-col tw-rounded-lg tw-border" v-for="step, j in item.steps" :key=j>
                        <div class="tw-flex-none">
                            <div class="tw-text-sm tw-py-1 tw-px-2 tw-w-20 tw-text-center tw-font-semibold tw-rounded tw-mb-2 tw-text-primary tw-bg-light">
                                {{ `Step ${step.count}` }}
                            </div>
                        </div>
                        <div class="tw-font-bold tw-text-lg">{{step.title}}</div>
                        <div class=" tw-text-gray-600 tw-text-sm " v-if="step.reminder">
                            <div class="tw-font-semibold tw-my-2">Reminders</div>
                            <div class="tw-flex tw-gap-2">
                                <v-icon>mdi-check-circle-outline</v-icon>
                                <div class=" tw-text-xs">{{step.reminder}}</div>
                            </div>
                        </div>
                        <div class=" tw-text-gray-600 tw-text-sm tw-mt-4">
                            {{ step.content }}
                        </div>
                        <div v-if="step.download" class=" tw-text-primary tw-text-sm tw-mt-4 tw-font-medium tw-text-center">
                            {{ step.download }}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section ref="faqsSection" class=" tw-py-20" >
            <div class="tw-font-bold tw-mb-16 tw-text-center " style="font-size: 35px">
                Frequently Asked Questions (FAQs)
            </div>
            <div class=" tw-max-w-5xl tw-mx-auto">
                <v-expansion-panels v-model="panel" class="elevation-0">
                    <v-expansion-panel class="my-3 rounded-lg elevation-0">
                        <v-expansion-panel-header style="font-weight: bold; padding: 20px 20px;">Getting Started</v-expansion-panel-header>
                        <v-expansion-panel-content class="elevation-0">
                            <p><strong>1. How do I log in?</strong><br>
                                <ul>
                                    <li>To log in, visit the LMS platform login page and enter your username (your registered email address) and password. If it's your first time logging in, use the temporary password provided to you. You will be prompted to change your password upon your first login.</li>
                                </ul>
                            </p>

                            <p><strong>2. What should I do if I can’t log in?</strong><br>
                                <ul>
                                    <li>If you are unable to log in, first ensure that you are using the correct username and password. If you still can’t log in, click on the "Forgot Password" link on the login page to reset your password. If the issue persists, contact our support team at info@pcitech.com.ph.</li>
                                </ul>
                            </p>

                            <p><strong>3. How do I reset my password?</strong><br>
                                <ul>
                                    <li>To reset your password, click on the "Forgot Password" link on the login page. Enter your registered email address, and you will receive an email with instructions on how to reset your password.</li>
                                </ul>
                            </p>

                            <p><strong>4. How do I update my profile information?</strong><br>
                                <ul>
                                    <li>To update your profile information, log in to the LMS platform and go to the "Profile" section from the navigation menu. Here, you can edit your personal details, change your password, and update your contact information.</li>
                                </ul>
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel class="my-3 rounded-lg elevation-0">
                        <v-expansion-panel-header style="font-weight: bold; padding: 20px 20px;">Course Enrollment and Access</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p><strong>1. How do I enroll in a course?</strong><br><br>
                            <strong>A. As a New Enrollee</strong> <br>
                            <ul>
                                    <li>As a new enrollee, you will be automatically enrolled in our LMS, starting with the Junior Module. Once the course is open, it will appear as "Open" on the Home page, and you can continue it in "My Course".</li>
                                </ul>
                            </p>

                            <p><strong>B. Eligible</strong><br>
                                <ul>
                                    <li>Please note your year level: if you are a 1st to 5th year student, you can take the Junior Level Course. If you are a 2nd to 5th year student, you will be automatically enrolled in this course. For 4th to 5th year students, once you complete the Junior and Intermediate course, you will be automatically enrolled in the Senior Module, which you can find in "My Course".</li>
                                </ul>
                                </p>

                            <p><strong>C. Retaker</strong><br>
                                <ul>
                                    <li>For retakers, you need to pay before enrolling in the course to gain access. Once the payment is cleared, you will be automatically enrolled in the specific course where you left off.</li>
                                </ul>
                                </p>

                                <p><strong>2. What should I do if I have no access to certain courses?</strong><br>
                                    <ul>
                                        <li>If you find that you do not have access to certain courses or materials, ensure that you are enrolled in the appropriate course. Additionally, make sure you have completed the prerequisite course with 100% progress, otherwise, you will not be enrolled in the next course.</li>
                                        <li>If the issue remains, please contact your course administrator or our support team at info@pcitech.com.ph for assistance.</li>
                                    </ul>
                                </p>

                            <p><strong>3. Do you see yourself using this type of platform in class?</strong><br>
                                <ul>
                                    <li>We encourage you to consider how the LMS platform can be integrated into your teaching methods. Your feedback on usability and applicability in classroom settings is invaluable to us.</li>
                                </ul>
                                </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel class="my-3 rounded-lg elevation-0">
                        <v-expansion-panel-header style="font-weight: bold; padding: 20px 20px;">Navigation and Usage</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p><strong>1. How do I navigate the LMS platform? </strong><br>
                                <ul>
                                    <li>After logging in, you will be taken to the dashboard. Here, you can access your courses, view announcements, check your progress, and manage your profile. Use the navigation menu on the left side to explore different sections of the platform.</li>
                                </ul>
                                </p>

                            <p><strong>2. How can I track my course progress?</strong><br>
                                <ul>
                                    <li>You can track your course progress by visiting the "My Courses" section. Here, you'll see a progress bar for each course you are enrolled in, indicating the percentage of the course you have completed.</li>
                                </ul>
                                </p>

                            <p><strong>3. Is there a time limit to complete my courses?</strong><br>
                                <ul>
                                    <li>Course completion times vary. Check the course details for specific deadlines. Some courses may have flexible schedules, while others may have strict timelines.</li>
                                </ul>
                                </p>

                            <p><strong>4. How do I participate in Small Group Discussion and Face to Face Workshop?</strong><br>
                                <ul>
                                    <li>Live sessions or webinars can be accessed through the "Junior SGD", "Face to Face Workshop", and “Senior SGD” course. The links are available on the said courses.</li>
                                </ul>
                                </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel class="my-3 rounded-lg elevation-0">
                        <v-expansion-panel-header style="font-weight: bold; padding: 20px 20px;">Dashboard Customization</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p><strong>1. Can I customize my dashboard or user interface?</strong><br>
                                <ul>
                                    <li>Some LMS platforms offer dashboard customization features that allow you to prioritize specific courses. You can filter courses by categories such as Junior level, Intermediate level, or Senior level, making it easier to focus on the courses that are most relevant to you at any given time. Also you can change the format for the courses.</li>
                                </ul>
                                </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel class="my-3 rounded-lg elevation-0">
                        <v-expansion-panel-header style="font-weight: bold; padding: 20px 20px;">Technical Support</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p><strong>1. What should I do if I encounter technical issues during a course?</strong><br>
                                <ul>
                                    <li>If you encounter technical issues while taking a course, try refreshing the page or closing your browser cache. If the problem persists, contact our technical support team at info@pcitech.com.ph.</li>
                                </ul>
                            </p>

                            <p><strong>2. How do I contact support?</strong><br>
                                <ul>
                                    <li>If you need assistance, you can contact our support team via email at info@pcitech.com.ph. Our support hours are 9:00 am to 5:00 pm.</li>
                                </ul>
                                </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel class="my-3 rounded-lg elevation-0">
                        <v-expansion-panel-header style="font-weight: bold; padding: 20px 20px'">Post-Test/Assessments</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p><strong>1. What should I do if I miss a deadline?</strong><br>
                                <ul>
                                    <li>If you miss a deadline, reach out to your course instructor as soon as possible to discuss your options. Depending on the course policy, you may be able to get an extension or make up the missed work.</li>
                                </ul>
                                </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel class="my-3 rounded-lg elevation-0">
                        <v-expansion-panel-header style="font-weight: bold; padding: 20px 20px;">Certification and Completion</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p><strong>1. How do I get certified?</strong><br>
                                <ul>
                                    <li>Certificates are awarded upon successful completion of a course. Once you have completed all required modules and passed the assessments, you will receive a notification, and your certificate will be available for download in the "Certificates" section of your profile.</li>
                                </ul>
                                </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </section>

        <div class=" tw-text-white tw-flex tw-items-center tw-justify-center tw-w-full tw-bg-primary" style="height: 250px">
            <div class="tw-max-w-5xl">
                <div class="tw-flex tw-gap-6 tw-items-center">
                    <div class=" tw-font-medium">Home</div>
                    <div class=" tw-font-medium">About MIS HOL</div>
                    <div class=" tw-font-medium">Announcements</div>
                    <div class=" tw-font-medium">MIS HOL Steps</div>
                    <div class=" tw-font-medium">FAQS</div>
                </div>
                <div class="tw-font-medium tw-text-center tw-my-5">Copyright © PSGS 2023. </div>
            </div>
        </div>
        <img src="/bar-line.png" class="tw-w-full">
    </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { api } from '@/services/axios'
import Login from "@/components/landing/forms/Login.vue";
import Register from "@/components/landing/forms/Register.vue";
import HomeCard from "@/components/landing/cards/home-card.vue";
import Section from '../admin/Section.vue';

export default {
    name: 'landing-home',
    components: {
        Login,
        Register,
        HomeCard,
        Section
    },
    data: () => ({
        announcements: [],
        type: "All",
        types: [
            "All",
            "News",
            "Trainings",
            "Conferences",
        ],
        showLogin: true,
        loading: false,
        tab: 0 ,
        expandedPanel: 0,
        panel: 0,
        tabs: [
            "Home",
            "About MIS HOL",
            "Announcements",
            "MIS HOL Steps",
            "FAQS"
        ],
        items: [
            {
                title: "JUNIOR LEVEL COURSE",
                description: "Participants: New Enrollees, Eligible, and Retakers (1st year to 5th year residents ONLY)",
                steps: [
                    {
                        title: "JUNIOR MODULE",
                        content: "All residents must complete the modules before proceeding to the Junior Online SGD.",
                        reminder: "",
                        count: 1,
                    },
                    {
                        title: "JUNIOR ONLINE SGD",
                        content: "Only the residents who completed the Junior Online Module are eligible to take Online SGD.",
                        reminder: "Completion of Junior Module",
                        count: 2,
                    },
                    {
                        title: "JUNIOR ONLINE POST-TEST",
                        content: "Only the residents who attended the SGD are eligible to go trough the Online Post Test.",
                        reminder: "Completion of Junior Online Post-test",
                        count: 3,
                    },
                    {
                        title: "JUNIOR CERTIFICATION",
                        content: "Certificates will be automatically generated once all steps for Junior Level are completed.",
                        reminder: "Completion of Junior Module, Junior SGD, and Junior Post-test",
                        count: 4,
                        download: "DON’T FORGET TO DOWNLOAD YOUR CERTIFICATES.",
                    }
                ]
            },
            {
                title: "INTERMEDIATE LEVEL COURSE",
                description: "Participants: New Enrollees, Eligible, and Retakers (2nd year to 5th year residents ONLY)",
                steps: [
                    {
                        title: "INTERMEDIATE ONLINE MODULE",
                        content: "All residents must complete the online modules before proceeding to the Intermediate Face to Face Workshop.",
                        reminder: "Completion of Junior Module",
                        count: 5,
                    },
                    {
                        title: "INTERMEDIATE FACE TO FACE WORKSHOP",
                        content: "Only the residents who completed the Intermediate Online Module are eligible to take the Online SGD.",
                        reminder: "Completion of Intermediate Module",
                        count: 6,
                    },
                    {
                        title: "INTERMEDIATE ONLINE POST-TEST",
                        content: "Only the residents who attended the SGD are eligible to go through the Online Post Test",
                        reminder: "Completion of Intermediate Online Post-test",
                        count: 7,
                    },
                    {
                        title: "INTERMEDIATE CERTIFICATION",
                        content: "Certificates will be automatically generated once all steps for Junior Level are completed.",
                        reminder: "Completion of Intermediate Module, Face to Face Workshop, and Intermediate Post-test",
                        count: 8,
                        download: "DON’T FORGET TO DOWNLOAD YOUR CERTIFICATES.",
                    }
                ]
            },
            {
                title: "SENIOR LEVEL COURSE",
                description: "Participants: New Enrollees, Eligible, and Retakers (4th year to 5th year residents ONLY)",
                steps: [
                    {
                        title: "SENIOR ONLINE MODULE",
                        content: "All residents must complete the online modules before proceeding to the Senior Online SGD.",
                        reminder: "Completion of Senior Level",
                        count: 9,
                    },
                    {
                        title: "SENIOR ONLINE SGD",
                        content: "Only the residents who completed the Senior Online Module are eligible to take the Online SGD.",
                        reminder: "Completion of Senior Module",
                        count: 10,
                    },
                    {
                        title: "SENIOR ONLINE POST-TEST",
                        content: "Only the residents who attended the SGD are eligible to go through the Online Post Test.",
                        reminder: "Completion of Senior Online Post-test",
                        count: 11,
                    },
                    {
                        title: "SENIOR CERTIFICATION",
                        content: "Certificates will be automatically generated once all steps for Senior Level are completed.",
                        reminder: "Completion of Senior Module, Senior SGD, and Senior Post-test.",
                        count: 12,
                        download: "DON’T FORGET TO DOWNLOAD YOUR CERTIFICATES.",
                    }
                ]
            }
        ],
        // questions: [
        //     {
        //         title: "Getting Started",
        //         items: [
        //             {
        //                 question: "How do I log in?",
        //                 answer: "To log in, visit the LMS platform login page and enter your username (your registered email address) and password. If it's your first time logging in, use the temporary password provided to you. You will be prompted to change your password upon your first login."
        //             },
        //             {
        //                 question: "What should I do if I can’t log in?",
        //                 answer: "If you are unable to log in, first ensure that you are using the correct username and password. If you still can’t log in, click on the 'Forgot Password' link on the login page to reset your password. If the issue persists, contact our support team at info@pcitech.com.ph.",
        //             },
        //             {
        //                 question: "How do I reset my password?",
        //                 answer: "To reset your password, click on the 'Forgot Password' link on the login page. Enter your registered email address, and you will receive an email with instructions on how to reset your password.",
        //             },
        //             {
        //                 question: "How do I update my profile information?",
        //                 answer: "To update your profile information, log in to the LMS platform and go to the "Profile" section from the navigation menu. Here, you can edit your personal details, change your password, and update your contact information.",
        //             },
        //         ]
        //     },
        //     {
        //         title: "Course Enrollment and Access",
        //         items: [
        //             {
        //                 question: "How do I enroll in a course?",
        //                 answer: ""
        //             },
        //             {
        //                 question: "",
        //                 answer: "",
        //             },
        //             {
        //                 question: "",
        //                 answer: "",
        //             },
        //             {
        //                 question: "",
        //                 answer: "",
        //             },
        //         ]
        //     },
        // ]
    }),
    beforeMount() {
        // this.$router.push('/login')
    },
    async mounted() {
        await this.getAnnouncements()

        this.loading = true
        this.getHomepageCourses().then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions([
            'getHomepageCourses'
        ]),
        
        scrollToSection(item) {
            this.tab = item
            switch (item) {
                case 0:
                this.$refs.homeSection.scrollIntoView({ behavior: "smooth" });
                break;
                case 1:
                this.$refs.aboutSection.scrollIntoView({ behavior: "smooth" });
                break;
                case 2:
                this.$refs.announcementSection.scrollIntoView({ behavior: "smooth" });
                break;
                case 3:
                this.$refs.courseSection.scrollIntoView({ behavior: "smooth" });
                break;
                case 4:
                this.$refs.faqsSection.scrollIntoView({ behavior: "smooth" });
                break;
                default:
                break;
            }
        },
        
        login(){
            this.$router.push('/login')
        },

        async getAnnouncements(type="") {
            await api.get(`/announcements?type=${type}`)
                .then((res) => {
                    this.announcements = res.data
                    // console.log(res.data)
                }).catch((err) => {
                    console.error(err)
                })
        },
        async fetchAnnouncements(type) {
            this.type = type
            if(type == "All") {
                type = ""
            }
            await this.getAnnouncements(type)
        } 
    },
    computed: {
        ...mapState({
            courses: (state) => state.other_courses
        }),
    }
}
</script>